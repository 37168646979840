import { getHost } from '../../../utils/hostDataFunction';
import { RegisterProps } from '../../Models/RegisterProps';

function genRandonString(length: number) {
  var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()';
  var charLength = chars.length;
  var result = '';
  for (var i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * charLength));
  }
  return result;
}

export const registerUserProspectRequest = async (user: RegisterProps) => {
  try {
    const uniId = getHost();
    let headers: any;

    const refKey = localStorage.getItem('refKey');
    if (!!refKey) {
      headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        uniId: uniId ? uniId : '',
        'ref-key': refKey,
      };
    } else {
      headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        uniId: uniId ? uniId : '',
      };
    }

    const response = await fetch(`${process.env.REACT_APP_API_URL}/chat/buddy/${user.id}/v2/register`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        message: user.message,
        email: user.email,
        phone: user.phone,
        whatsappNumber: user.whatsappNumber,
        name: user.name,
        channels: user.channels,
        ip: user.ip,
        // programmeId: user.courseId?.join(', '),
        courseId: user.courseId?.join(', '),
        applicationNumber: user?.applicationNumber,
        countryCode: user?.countryCode,
        dialCode: user?.dialCode,
        city: user?.city,
        address: user?.address,
      }),
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      throw new Error(errorResponse.message);
    }

    return response.json();
  } catch (error: any) {
    throw new Error(error);
  }
};
